<script setup>
import helper from '~/resources/js/libraries/helper';
import MobileListingCardFavorite from './favorite-card';
import MobileListingContact from './listing-contact';

defineProps({
  listing: {
    type: Object,
  }
});
const currentCurrency = helper.cookies.currency();
const isOpen = ref(false);
</script>

<template>
  <div class="relative mb-4">
    <NuxtLink :to="localePath({ name: 'listings.show', params: { slug: listing.slug } })">
      <lightbox :images="listing.gallery_pictures" displayStyle="mobile" :disableOnShow="true" />
      <div>
        <h2 class="text-black text-xl font-bold my-2">
          {{ currentCurrency.code }}
          {{ helper.formatPrice(listing.sale_price) }}
        </h2>
        <div class="flex items-center opacity-60 text-black gap-8 text-base mb-2">
          <div class="flex items-center gap-2" v-for="feature in listing.features">
            <i :class="feature.icon"></i>
            <span>{{ feature.value.label_value || feature.value }}</span>
          </div>
        </div>
        <h3 class="text-base mb-1">{{ listing.title }}</h3>
        <p class="opacity-60 text-black text-xs">Type: {{ listing.category_name }}</p>
      </div>
      
    </NuxtLink>
    <div class="absolute top-0 right-2 flex items-center justify-between p-2 w-full">
        <mobile-listing-card-favorite :listingId="listing._id" :showBackground="true" heartColor="text-gray-800" @click.stop></mobile-listing-card-favorite>
    </div>
    <div class="flex -center gap-4 mt-3 justify-center">
      <mobile-listing-contact :listing="listing"></mobile-listing-contact>

      <a :href="listing.whatsapp_message"
        class="w-full py-2.5 rounded-lg bg-gray-200 text-indigo-900 text-center" target="_blank"><i
          class="fa-brands fa-whatsapp me-2 fa-lg"></i>Whatsapp
      </a>
    </div>
  </div>
</template>